export const listingUrlFromDetail = (listingDetail) => {
  const currentRentOrBuy =
    listingDetail?.listing_for == 1 ? "dijual" : "disewa";
  const listingUrl = [
    currentRentOrBuy.toLowerCase(),
    // listingDetail?.category.toLowerCase(),
    listingDetail?.district.district_name.toLowerCase(),
    listingDetail?.product_name.toLowerCase(),
    listingDetail?.state?.state_name.toLowerCase(),
    listingDetail?.id,
  ];

  return listingUrl.join("-").replace(/[\W_]+/g, "-");
};
