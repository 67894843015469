import { gql } from "@apollo/client";
import { initializeApollo } from "../apollo-client";

export const getSuggestion = async function (suggestionQuery) {
  const client = initializeApollo();
  return await client.query({
    query: gql`
            query suggestion {
                location(name: "${suggestionQuery}", limit: 10) {
                    object_id
                    object_name
                    object_type
                    state {
                      state_name
                      island
                    }
                    district {
                      district_name
                      state {
                        state_name
                      }
                    }
                    area {
                      area_name
                      district {
                        district_name
                        state {
                          state_name
                        }
                      }
                    }
                }
            }
        `,
  });
};

export const getStateList = async function () {
  const client = initializeApollo();
  return await client.query({
    query: gql`
      query stateList {
        state {
          states(has_listing: true) {
            state_id
            state_name
            island
            slug
          }
        }
      }
    `,
  });
};
