import React from "react";

const CurrencyFormat = (props) => {
  const { currency, amount } = props;

  let formatedAmout = null;
  if (amount >= 1000 && amount < 1000000) {
    formatedAmout = parseFloat(amount / 1000) + "rb";
  } else if (amount >= 1000000 && amount < 1000000000) {
    formatedAmout = parseFloat(amount / 1000000.0) + "jt";
  } else if (amount >= 1000000000 && amount < 1000000000000) {
    formatedAmout = parseFloat(amount / 1000000000) + "M";
  } else {
    formatedAmout = amount + " Rupiah";
  }

  return (
    <>
      {currency} {formatedAmout}
    </>
  );
};

CurrencyFormat.defaultProps = {
  currency: "Rp.",
};

export default CurrencyFormat;
